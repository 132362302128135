import axios from 'axios'
import Qs from 'qs'

// 发送埋点数据
export function sendTrack(url, params) {
  //请求发送方式
  if (navigator.sendBeacon) {
    let formData = new FormData()
    for (let key in params) {
      formData.append(key, params[key])
    }
    navigator.sendBeacon(url, formData)
  } else {
    axios.post(url, Qs.stringify(params))
  }
}
